<script>

import Icon from "../../Icon";
import DefaultCardHeader from "../default/DefaultCardHeader";
import DefaultCard from "../default/DefaultCard";
import HeatingCardMetricList from "./HeatingCardMetricList";

export default {
    name: "HeatingCard",
    extends: DefaultCard,
    components: {
        HeaderComponent: DefaultCardHeader,
        MetricList: HeatingCardMetricList,
        Icon
    }
}
</script>
