<template>
    <div>
        <div class="deviceCardMetricItem d-flex align-items-center justify-content-between w-100 font-weight-500"
             v-for="(metric, index) in deviceMetrics"
             style="height: 57px"
             :key="index">
            <div class="w-50 p-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="pr-2">
                        <div class="pr-1">
                        <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                            {{ metric.getPortIndex() + 1 }}
                        </span>
                            <Icon :icon-name="metric.getIcon()"
                                  width="23"
                                  height="23"
                                  :fill="metric.getColor()"/>
                        </div>
                    </div>
                    <div v-if="metric.getName().length > 37"
                         data-toggle="tooltip"
                         :title="metric.getName()">
                        {{ stringLimit(metric.getName(), 37) }}
                    </div>
                    <div v-else>
                        {{ metric.getName() }}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center w-17 border-left h-100"
                 :class="{'bg-accident': metricHasAccident(metric)}">
                <div v-if="metric.getSlug() !== 'heating'">{{ getMetricLabel(metric) }}</div>
                <div v-else>{{ status ? $t('Включено') : $t('Выключено') }}</div>
            </div>
            <div class="w-33 px-3 pt-1 border-left h-100">
                <CardBarChart :width="100" :height="53" :metricTimeseries="getMetricTimeseries(metric)" :animationDuration="0" v-if="getMetricTimeseries(metric).length"/>
                <div class="d-flex align-items-center justify-content-center h-100" v-else>N/A</div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultCardMetricList from "../default/DefaultCardMetricList";

export default {
    name: "HeatingCardMetricList",
    extends: DefaultCardMetricList,
    computed: {
        status() {
            const heating = this.deviceMetrics.find(metric => metric.getSlug() === 'heating');
            return parseInt(this.getMetricLabel(heating, 0)) === 1
        }
    }
}
</script>
